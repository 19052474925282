import React, { useEffect, useMemo } from 'react';

import classNames from 'classnames';

import { MdSearch } from 'react-icons/md';

import { getScrollParents, offset, useFloating } from '@floating-ui/react-dom';

import BaseInput, { Props as BaseProps } from './BaseInput';

export interface Option<T> {
  key: T | null;
  value: string;
}

export interface SearchInputProps<T> {
  options?: Option<T>[];
  selectedOption?: Option<T> | null;
  handleSelect?: (item: Option<T>) => void;
  value?: string | number | readonly string[];
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function SearchInput({
  value,
  options,
  onChange,
  handleSelect,
  selectedOption,
  ...props
}: SearchInputProps<string | number> &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
    HTMLInputElement
  >) {
  const { y, reference, floating, strategy, update, refs } = useFloating({
    placement: 'bottom',
    middleware: [
      offset({
        mainAxis: 5,
      }),
    ],
  });

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return;
    }

    const parents = [
      ...getScrollParents(refs.reference.current),
      ...getScrollParents(refs.floating.current),
    ];

    parents.forEach((parent) => {
      parent.addEventListener('scroll', update);
      parent.addEventListener('resize', update);
    });

    return () => {
      parents.forEach((parent) => {
        parent.removeEventListener('scroll', update);
        parent.removeEventListener('resize', update);
      });
    };
  }, [update, refs.reference, refs.floating]);

  const handleFilter = useMemo(() => {
    if (!options) return [];

    return options.filter((item) => {
      return item.value
        .toLowerCase()
        .includes(value?.toString().toLowerCase() || '');
    });
  }, [options, value]);

  const SHOW_OPTIONS =
    !!options && handleFilter.length > 0 && !!value && !selectedOption;

  return (
    <div
      ref={reference}
      className="flex flex-col w-full items-center gap-2 relative"
    >
      <div
        className={classNames(
          'flex items-center justify-start w-full border border-gray-dark500 bg-gray-dark600 shadow-none rounded-md px-4',
          {
            'bg-gray-dark700': props.disabled,
          }
        )}
      >
        <MdSearch size={25} color="#6B6B6B" />
        <BaseInput
          {...props}
          type="text"
          value={value}
          autoComplete="off"
          onChange={onChange}
          className="appearence-none border-none focus:outline-none focus:ring-transparent w-full bg-gray-dark600 shadow-none rounded-md placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark400 text-sm"
        />
      </div>
      {SHOW_OPTIONS && (
        <div
          className="flex w-full items-start justify-start flex-col border border-gray-dark500 rounded-md bg-gray-dark600 max-h-[308px] overflow-y-auto focus:outline-none absolute z-10"
          style={{
            top: y ?? '',
            position: strategy,
          }}
          ref={floating}
        >
          {handleFilter?.map((item) => (
            <p
              key={item.key}
              onClick={() => handleSelect?.(item)}
              className={
                'flex flex-col w-full items-start justify-center bg-gray-dark600 shadow-none rounded-md placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark500 text-sm hover:border-primary px-4 py-3 cursor-pointer hover:text-gray-dark400 text-sm hover:bg-gray-dark550'
              }
            >
              {item.value}
            </p>
          ))}
        </div>
      )}
    </div>
  );
}

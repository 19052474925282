import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';

import { MdChevronLeft, MdSearch } from 'react-icons/md';

import { getScrollParents, offset, useFloating } from '@floating-ui/react-dom';

import CheckboxField from 'ui/form/CheckboxField';

import BaseInput, { Props as BaseProps } from './BaseInput';

export interface Option<T> {
  key: T | null;
  value: string;
}

export interface MultipleSearchInputProps<T> {
  isOpen?: boolean;
  options?: Option<T>[];
  isAllSelectable?: boolean;
  selectedOption?: Option<T>[] | null;
  selectItem?: (item: Option<T>) => void;
  value?: string | number | readonly string[];
  selectAll?: (item: Option<string | number>[]) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MultipleSearchInput({
  value,
  isOpen,
  options,
  onChange,
  selectAll,
  selectItem,
  selectedOption,
  isAllSelectable,
  ...props
}: MultipleSearchInputProps<string | number> &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement> & BaseProps,
    HTMLInputElement
  >) {
  const [selectedOptionReference, setSelectedOptionReference] = useState({
    value: '',
    counter: 0,
  });

  const [isOptionsOpen, setIsOptionsOpen] = useState(isOpen || false);

  const [optionsList, setOptionsList] = useState<Option<string | number>[]>(
    options || []
  );

  const { y, reference, floating, strategy, update, refs } = useFloating({
    placement: 'bottom',
    middleware: [
      offset({
        mainAxis: 5,
      }),
    ],
  });

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return;
    }

    const parents = [
      ...getScrollParents(refs.reference.current),
      ...getScrollParents(refs.floating.current),
    ];

    parents.forEach((parent) => {
      parent.addEventListener('scroll', update);
      parent.addEventListener('resize', update);
    });

    return () => {
      parents.forEach((parent) => {
        parent.removeEventListener('scroll', update);
        parent.removeEventListener('resize', update);
      });
    };
  }, [update, refs.reference, refs.floating]);

  useEffect(() => {
    isAllSelectable &&
      options &&
      setOptionsList(
        options.length > 1
          ? [{ key: null, value: 'Selecionar todas' }, ...(options || [])]
          : [...(options || [])]
      );
  }, [isAllSelectable, options]);

  const handleFilter = useMemo(() => {
    if (!optionsList) {
      return [];
    }

    return optionsList.filter(
      (item) =>
        (item.key === null && isAllSelectable) ||
        item.value.toLowerCase().includes(value?.toString().toLowerCase() || '')
    );
  }, [isAllSelectable, optionsList, value]);

  const IS_ALL_SELECTED = selectedOption?.length === options?.length;

  const SHOW_OPTIONS =
    (handleFilter?.length > 0 && !!value) ||
    (isOptionsOpen && optionsList?.length > 0 && !props.disabled);

  const handleSelect = useCallback(
    (item: Option<string | number>) => {
      isAllSelectable && item.key === null
        ? selectAll?.(options || [])
        : selectItem?.(item);
    },
    [isAllSelectable, options, selectAll, selectItem]
  );

  const handleCheck = useMemo(
    () => (item: Option<string | number>) => {
      return isAllSelectable && item.key === null
        ? IS_ALL_SELECTED
        : selectedOption?.some((option) => option.key === item.key);
    },
    [selectedOption, isAllSelectable, IS_ALL_SELECTED]
  );

  const handleValue = useMemo(
    () => () => {
      if (selectedOption?.length === 1) {
        return setSelectedOptionReference({
          counter: 0,
          value: selectedOption[0].value,
        });
      }

      return setSelectedOptionReference(
        (selectedOption &&
          selectedOption?.length > 1 && {
            value: selectedOption[0].value,
            counter: selectedOption?.length - 1,
          }) || { value: '', counter: 0 }
      );
    },
    [selectedOption]
  );

  useEffect(() => {
    handleValue();
  }, [handleValue]);

  return (
    <div
      ref={reference}
      className="flex flex-col w-full items-center gap-2 relative"
    >
      <div
        className={classNames(
          'flex items-center justify-between w-full border border-gray-dark500 bg-gray-dark600 shadow-none rounded-md px-4',
          {
            'bg-gray-dark700': props.disabled,
          }
        )}
      >
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center justify-start w-full">
            <MdSearch size={25} color="#6B6B6B" />
            <BaseInput
              {...props}
              type="text"
              value={value}
              autoComplete="off"
              onChange={onChange}
              className="appearence-none border-none focus:outline-none focus:ring-transparent w-full bg-gray-dark600 shadow-none rounded-md placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark400 text-sm"
            />
          </div>
          {!!selectedOptionReference.value && (
            <div
              className="grid items-center justify-end gap-2 px-2"
              style={{
                width: '300px',
                gridTemplateColumns:
                  selectedOptionReference.counter > 0 ? '1fr 35px' : '1fr',
              }}
            >
              <span
                className="text-gray-dark400 text-sm"
                style={{
                  overflow: 'hidden',
                  textAlign: 'right',
                  whiteSpace: 'nowrap',
                  display: 'inline-block',
                  textOverflow: 'ellipsis',
                }}
              >
                {selectedOptionReference.value}
              </span>
              {selectedOptionReference.counter > 0 && (
                <span
                  className="text-gray-dark400 text-sm w-full"
                  style={{
                    display: 'inline-block',
                  }}
                >
                  + {selectedOptionReference.counter}
                </span>
              )}
            </div>
          )}
        </div>
        <MdChevronLeft
          size={20}
          color="#6B6B6B"
          aria-hidden="true"
          className="cursor-pointer"
          style={{ transform: `rotate(${isOptionsOpen ? 90 : -90}deg)` }}
          onClick={() => optionsList.length > 0 && setIsOptionsOpen(!isOptionsOpen)}
        />
      </div>
      {SHOW_OPTIONS && (
        <div
          className="flex w-full items-start justify-start flex-col border border-gray-dark500 rounded-md bg-gray-dark600 max-h-[308px] overflow-y-auto focus:outline-none absolute z-10"
          style={{
            top: y ?? '',
            position: strategy,
          }}
          ref={floating}
        >
          {handleFilter?.map((item) => (
            <div
              key={item.key}
              className={classNames(
                'flex w-full items-center justify-start bg-gray-dark600 shadow-none placeholder-gray-dark500 focus-within:text-gray-dark400 text-gray-dark500 text-sm hover:border-primary hover:text-gray-dark400 text-sm hover:bg-gray-dark550',
                {
                  'bg-gray-dark700': props.disabled,
                  'bg-gray-dark550': handleCheck(item),
                }
              )}
            >
              <CheckboxField
                inputLabel={item.value}
                checked={handleCheck(item)}
                onClick={() => handleSelect(item)}
                wrapperClassName={
                  'flex items-center justify-start w-full cursor-pointer ml-4'
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

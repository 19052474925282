import { useCallback, useEffect, useMemo, useState } from 'react';

import { MdClose } from 'react-icons/md';

import {
  Control,
  useWatch,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';
import { ModalProps } from 'ui/models/overlay';
import { useModal } from 'ui/contexts/overlay/Modal';
import { Option } from 'ui/components/form/SearchInput';
import Accordion from 'ui/components/accordion/Accordion';

import { RemoveButton } from 'dashboard/components/table/buttons';

import {
  Contacts,
  Entities,
  ContactTypes,
} from 'contactGroups/models/contactGroups';

import ContactGroupsDangerModal from '../modal/ContactGroupsDangerModal';

import { Cooperative, CooperativeSelect } from 'cooperatives/models/cooperative';

import { ConsumerUnit, ConsumerUnitsSelect } from 'consumerUnits/model/consumerUnit';

import {
  GenerationUnit,
  GenerationUnitSelect,
} from 'generationUnits/models/generationUnit';
import MultipleSearchInput from 'ui/components/form/MultipleSearchInput';

export type ContactGroupsFormFields = {
  groupName: string;
  entities: Entities;
  contacts: Contacts[];
};

export type ContactsFormInputs = {
  ownerContactName: string;
  ownerContactEmail: string;
  ownerContactPhone: string;
  domainContactName: string;
  domainContactEmail: string;
  domainContactPhone: string;
  technicalContactName: string;
  technicalContactEmail: string;
  technicalContactPhone: string;
  juridicalContactName: string;
  juridicalContactEmail: string;
  juridicalContactPhone: string;
  financialContactName: string;
  financialContactEmail: string;
  financialContactPhone: string;
  administrativeContactName: string;
  administrativeContactEmail: string;
  administrativeContactPhone: string;
};

export type ContactGroupsFormInputs = ContactsFormInputs | ContactGroupsFormFields;

export type ContactGroupsFormErrors = {
  contacts?: FieldError;
  entities?: FieldError;
  groupName?: FieldError;
  cooperativeId?: FieldError;
  consumerUnitId?: FieldError;
  generationUnitId?: FieldError;
  cooperativeMemberId?: FieldError;
};

type Field = 'groupName' | 'contacts' | 'entities';

export const FORM_FIELDS: Field[] = ['groupName', 'contacts', 'entities'];

const DANGER_TOAST: ModalProps = {
  variant: 'danger',
  title: 'Atenção!',
  text: 'Você tem certeza que deseja cadastrar esse email como responsável por esse grupo de contatos? Não esqueça que esse email será o login da área do cliente e não será possível editar esse e-mail.',
};

export default function ContactGroupsFormFieldsComponent({
  errors,
  control,
  setValue,
  register,
  cooperatives,
  consumerUnits,
  generationUnits,
  handleGetEntityList,
  handleGetContactList,
}: {
  errors: ContactGroupsFormErrors;
  cooperatives: CooperativeSelect[];
  consumerUnits: ConsumerUnitsSelect[];
  generationUnits: GenerationUnitSelect[];
  control: Control<ContactGroupsFormInputs>;
  setValue: UseFormSetValue<ContactGroupsFormInputs>;
  register: UseFormRegister<ContactGroupsFormInputs>;
  handleGetEntityList: (entities: Entities) => void;
  handleGetContactList: (contacts: Contacts[]) => void;
}) {
  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const [contacts, setContacts] = useState<Contacts[]>([]);

  const [domainContact, setDomainContact] = useState<Contacts>();

  const [entities, setEntities] = useState<Entities>({
    cooperative: [],
    consumerUnit: [],
    generationUnit: [],
  });

  const [selectOptions, setSelectOptions] = useState({
    cooperative: [] as Option<number>[],
    consumerUnit: [] as Option<number>[],
    generationUnit: [] as Option<number>[],
  });

  const [entitySearchBarValue, setEntitySearchBarValue] = useState({
    cooperative: '',
    consumerUnit: '',
    generationUnit: '',
  });

  const [entitySearchBarSelectedOption, setEntitySearchBarSelectedOption] = useState(
    {
      cooperative: null as Option<string | number>[] | null,
      consumerUnit: null as Option<string | number>[] | null,
      generationUnit: null as Option<string | number>[] | null,
    }
  );

  const [
    ownerContactName,
    ownerContactEmail,
    ownerContactPhone,
    domainContactName,
    domainContactEmail,
    domainContactPhone,
    financialContactName,
    financialContactEmail,
    financialContactPhone,
    technicalContactName,
    technicalContactEmail,
    technicalContactPhone,
    juridicalContactName,
    juridicalContactEmail,
    juridicalContactPhone,
    administrativeContactName,
    administrativeContactEmail,
    administrativeContactPhone,
  ] = useWatch({
    control,
    name: [
      'ownerContactName',
      'ownerContactEmail',
      'ownerContactPhone',
      'domainContactName',
      'domainContactEmail',
      'domainContactPhone',
      'financialContactName',
      'financialContactEmail',
      'financialContactPhone',
      'technicalContactName',
      'technicalContactEmail',
      'technicalContactPhone',
      'juridicalContactName',
      'juridicalContactEmail',
      'juridicalContactPhone',
      'administrativeContactName',
      'administrativeContactEmail',
      'administrativeContactPhone',
    ],
  });

  const handleChangeConsumerUnit = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntitySearchBarValue({
        ...entitySearchBarValue,
        consumerUnit: e.target.value,
      });
    },
    [entitySearchBarValue]
  );

  const handleChangeGenerationUnit = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntitySearchBarValue({
        ...entitySearchBarValue,
        generationUnit: e.target.value,
      });
    },
    [entitySearchBarValue]
  );

  const handleChangeCooperative = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEntitySearchBarValue({
        ...entitySearchBarValue,
        cooperative: e.target.value,
      });
    },
    [entitySearchBarValue]
  );

  useEffect(() => {
    !!consumerUnits &&
      setSelectOptions((prev) => ({
        ...prev,
        consumerUnit:
          consumerUnits.map((consumerUnit) => ({
            key: consumerUnit.id,
            value: `${consumerUnit?.consumerUnitPowerDistributionUnitIdentifier} - ${consumerUnit?.cooperativeMember?.cooperativeMemberLegalName} - ${consumerUnit?.cooperativeMember?.cooperativeMemberDocumentId}`,
          })) || [],
      }));
  }, [consumerUnits]);

  useEffect(() => {
    !!generationUnits &&
      setSelectOptions((prev) => ({
        ...prev,
        generationUnit:
          generationUnits.map((generationUnit) => ({
            key: generationUnit.id,
            value: `${generationUnit.generationUnitLegalName} - ${generationUnit.powerDistributionUnit.powerDistributionUnitLegalName}`,
          })) || [],
      }));
  }, [generationUnits]);

  useEffect(() => {
    !!cooperatives &&
      setSelectOptions((prev) => ({
        ...prev,
        cooperative:
          cooperatives.map((cooperative) => ({
            key: cooperative.id,
            value: `${cooperative.cooperativeLegalName} - ${cooperative.cooperativeAddressState}`,
          })) || [],
      }));
  }, [cooperatives]);

  const handleClearContactInputs = useCallback(
    (contactType: ContactTypes) => {
      switch (contactType) {
        case ContactTypes.DOMAIN:
          setValue('domainContactName', '');
          setValue('domainContactEmail', '');
          setValue('domainContactPhone', '');
          break;
        case ContactTypes.OWNER:
          setValue('ownerContactName', '');
          setValue('ownerContactEmail', '');
          setValue('ownerContactPhone', '');
          break;
        case ContactTypes.TECHNICAL:
          setValue('technicalContactName', '');
          setValue('technicalContactEmail', '');
          setValue('technicalContactPhone', '');
          break;
        case ContactTypes.JURIDICAL:
          setValue('juridicalContactName', '');
          setValue('juridicalContactEmail', '');
          setValue('juridicalContactPhone', '');
          break;
        case ContactTypes.FINANCIAL:
          setValue('financialContactName', '');
          setValue('financialContactEmail', '');
          setValue('financialContactPhone', '');
          break;
        case ContactTypes.ADMINISTRATIVE:
          setValue('administrativeContactName', '');
          setValue('administrativeContactEmail', '');
          setValue('administrativeContactPhone', '');
          break;
      }
    },
    [setValue]
  );

  const handleClearEntityInputs = useCallback(
    (entityType: keyof Entities) => {
      switch (entityType) {
        case 'cooperative':
          setEntitySearchBarValue({
            ...entitySearchBarValue,
            cooperative: '',
          });

          setEntitySearchBarSelectedOption({
            ...entitySearchBarSelectedOption,
            cooperative: null,
          });
          break;
        case 'consumerUnit':
          setEntitySearchBarValue({
            ...entitySearchBarValue,
            consumerUnit: '',
          });

          setEntitySearchBarSelectedOption({
            ...entitySearchBarSelectedOption,
            consumerUnit: null,
          });
          break;
        case 'generationUnit':
          setEntitySearchBarValue({
            ...entitySearchBarValue,
            generationUnit: '',
          });

          setEntitySearchBarSelectedOption({
            ...entitySearchBarSelectedOption,
            generationUnit: null,
          });
          break;
      }
    },
    [entitySearchBarSelectedOption, entitySearchBarValue]
  );

  const handleAddContact = useCallback(
    (
      contactType: ContactTypes,
      contactName: string,
      contactEmail: string,
      contactPhone: string
    ) => {
      setContacts((prev) => [
        ...prev,
        {
          contactType,
          contactName,
          contactEmail,
          contactPhone,
        } as Contacts,
      ]);

      handleClearContactInputs(contactType);
    },
    [handleClearContactInputs]
  );

  const findSelectedConsumerUnit = useCallback(
    (id: string) => {
      return consumerUnits.find((consumerUnit) => String(consumerUnit.id) === id);
    },
    [consumerUnits]
  );

  const findSelectedGenerationUnit = useCallback(
    (id: string) => {
      return generationUnits.find(
        (generationUnit) => String(generationUnit.id) === id
      );
    },
    [generationUnits]
  );

  const findSelectedCooperative = useCallback(
    (id: string) => {
      return cooperatives.find((cooperative) => String(cooperative.id) === id);
    },
    [cooperatives]
  );

  const handleAddEntity = useCallback(
    (entityType: keyof Entities) => {
      handleClearEntityInputs(entityType);

      switch (entityType) {
        case 'cooperative':
          const cooperative = entitySearchBarSelectedOption.cooperative?.map(
            (option) => findSelectedCooperative(option.key as string)
          );

          entitySearchBarSelectedOption.cooperative?.map((selectedOption) =>
            setSelectOptions((prev) => ({
              ...prev,
              cooperative: prev.cooperative.filter(
                (option) => option.key !== selectedOption.key
              ),
            }))
          );

          return cooperative?.map((cooperative) =>
            setEntities((prev) => ({
              ...prev,
              cooperative: [
                ...prev.cooperative,
                {
                  id: cooperative?.id,
                  cooperativeLegalName: cooperative?.cooperativeLegalName,
                  cooperativeDocumentId: cooperative?.cooperativeDocumentId,
                  cooperativeAddressState: cooperative?.cooperativeAddressState,
                  financialAccount: {
                    financialAccountLegalName:
                      cooperative?.financialAccount?.financialAccountLegalName,
                  },
                } as Cooperative,
              ],
            }))
          );
        case 'consumerUnit':
          const consumerUnit = entitySearchBarSelectedOption.consumerUnit?.map(
            (option) => findSelectedConsumerUnit(option.key as string)
          );

          entitySearchBarSelectedOption.consumerUnit?.map((selectedOption) =>
            setSelectOptions((prev) => ({
              ...prev,
              consumerUnit: prev.consumerUnit.filter(
                (option) => option.key !== selectedOption.key
              ),
            }))
          );

          return consumerUnit?.map((consumerUnit) =>
            setEntities((prev) => ({
              ...prev,
              consumerUnit: [
                ...prev.consumerUnit,
                {
                  id: consumerUnit?.id,
                  cooperativeMember: {
                    cooperativeMemberLegalName:
                      consumerUnit?.cooperativeMember?.cooperativeMemberLegalName,
                    cooperativeMemberDocumentId:
                      consumerUnit?.cooperativeMember?.cooperativeMemberDocumentId,
                  },
                  consumerUnitPowerDistributionUnitIdentifier:
                    consumerUnit?.consumerUnitPowerDistributionUnitIdentifier,
                  consumerUnitAddressDistrict:
                    consumerUnit?.consumerUnitAddressDistrict,
                  powerDistributionUnit: {
                    powerDistributionUnitLegalName:
                      consumerUnit?.powerDistributionUnit
                        ?.powerDistributionUnitLegalName,
                  },
                } as ConsumerUnit,
              ],
            }))
          );

        case 'generationUnit':
          const generationUnit = entitySearchBarSelectedOption.generationUnit?.map(
            (option) => findSelectedGenerationUnit(option.key as string)
          );

          entitySearchBarSelectedOption.generationUnit?.map((selectedOption) =>
            setSelectOptions((prev) => ({
              ...prev,
              generationUnit: prev.generationUnit.filter(
                (option) => option.key !== selectedOption.key
              ),
            }))
          );

          return generationUnit?.map((generationUnit) =>
            setEntities((prev) => ({
              ...prev,
              generationUnit: [
                ...prev.generationUnit,
                {
                  id: generationUnit?.id,
                  generationUnitLegalName: generationUnit?.generationUnitLegalName,
                  financialAccount: {
                    financialAccountLegalName:
                      generationUnit?.financialAccount?.financialAccountLegalName,
                  },
                  powerDistributionUnit: {
                    powerDistributionUnitLegalName:
                      generationUnit?.powerDistributionUnit
                        ?.powerDistributionUnitLegalName,
                  },
                } as GenerationUnit,
              ],
            }))
          );
      }
    },
    [
      handleClearEntityInputs,
      findSelectedCooperative,
      findSelectedConsumerUnit,
      findSelectedGenerationUnit,
      entitySearchBarSelectedOption.cooperative,
      entitySearchBarSelectedOption.consumerUnit,
      entitySearchBarSelectedOption.generationUnit,
    ]
  );

  const handleSelectConsumerUnit = useCallback(
    (item: Option<string | number>) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        consumerUnit: entitySearchBarSelectedOption.consumerUnit?.some(
          (option) => option.key === item.key
        )
          ? entitySearchBarSelectedOption.consumerUnit?.filter(
              (option) => option.key !== item.key
            )
          : [...(entitySearchBarSelectedOption.consumerUnit || []), item],
      });
    },
    [entitySearchBarSelectedOption]
  );

  const handleSelectAllConsumerUnits = useCallback(
    (item: Option<string | number>[]) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        consumerUnit: entitySearchBarSelectedOption.consumerUnit
          ? entitySearchBarSelectedOption.consumerUnit?.length === item.length
            ? []
            : item
          : item,
      });
    },
    [entitySearchBarSelectedOption]
  );

  const handleSelectGenerationUnit = useCallback(
    (item: Option<string | number>) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        generationUnit: entitySearchBarSelectedOption.generationUnit?.some(
          (option) => option.key === item.key
        )
          ? entitySearchBarSelectedOption.generationUnit?.filter(
              (option) => option.key !== item.key
            )
          : [...(entitySearchBarSelectedOption.generationUnit || []), item],
      });
    },
    [entitySearchBarSelectedOption]
  );

  const handleSelectAllGenerationUnits = useCallback(
    (item: Option<string | number>[]) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        generationUnit: entitySearchBarSelectedOption.generationUnit
          ? entitySearchBarSelectedOption.generationUnit?.length === item.length
            ? []
            : item
          : item,
      });
    },
    [entitySearchBarSelectedOption]
  );

  const handleSelectCooperative = useCallback(
    (item: Option<string | number>) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        cooperative: entitySearchBarSelectedOption.cooperative?.some(
          (option) => option.key === item.key
        )
          ? entitySearchBarSelectedOption.cooperative?.filter(
              (option) => option.key !== item.key
            )
          : [...(entitySearchBarSelectedOption.cooperative || []), item],
      });
    },
    [entitySearchBarSelectedOption]
  );

  const handleSelectAllCooperatives = useCallback(
    (item: Option<string | number>[]) => {
      setEntitySearchBarSelectedOption({
        ...entitySearchBarSelectedOption,
        cooperative: entitySearchBarSelectedOption.cooperative
          ? entitySearchBarSelectedOption.cooperative?.length === item.length
            ? []
            : item
          : item,
      });
    },
    [entitySearchBarSelectedOption]
  );

  const filteredContacts = useMemo(
    () => (contactType: ContactTypes) => {
      return contacts.filter((contact) => contact.contactType === contactType);
    },
    [contacts]
  );

  const filteredCooperatives = useMemo(() => {
    return entities?.cooperative?.filter((cooperative) => !!cooperative);
  }, [entities]);

  const filteredConsumerUnits = useMemo(() => {
    return entities?.consumerUnit?.filter((consumerUnit) => !!consumerUnit);
  }, [entities]);

  const filteredGenerationUnits = useMemo(() => {
    return entities?.generationUnit?.filter((generationUnit) => !!generationUnit);
  }, [entities]);

  const handleRemoveContact = useCallback(
    (index: number) => {
      setContacts(contacts.filter((_, contactIndex) => contactIndex !== index));
    },
    [contacts]
  );

  const handleRemoveConsumerUnitEntity = useCallback(
    (index: number) => {
      const consumerUnit = entities?.consumerUnit?.find(
        (_, consumerUnitIndex) => consumerUnitIndex === index
      );

      setSelectOptions((prev) => ({
        ...prev,
        consumerUnit: [
          ...prev.consumerUnit,
          {
            key: consumerUnit?.id as number,
            value: `${consumerUnit?.consumerUnitPowerDistributionUnitIdentifier} - ${consumerUnit?.cooperativeMember?.cooperativeMemberLegalName} - ${consumerUnit?.cooperativeMember?.cooperativeMemberDocumentId}`,
          },
        ],
      }));

      setEntities((prev) => ({
        ...prev,
        consumerUnit: prev.consumerUnit.filter(
          (_, consumerUnitIndex) => consumerUnitIndex !== index
        ),
      }));
    },
    [entities?.consumerUnit]
  );

  const handleRemoveCooperativeEntity = useCallback(
    (index: number) => {
      const cooperative = entities?.cooperative?.find(
        (_, cooperativeIndex) => cooperativeIndex === index
      );

      setSelectOptions((prev) => ({
        ...prev,
        cooperative: [
          ...prev.cooperative,
          {
            key: cooperative?.id as number,
            value: `${cooperative?.cooperativeLegalName} - ${cooperative?.cooperativeAddressState}`,
          },
        ],
      }));

      setEntities((prev) => ({
        ...prev,
        cooperative: prev.cooperative.filter(
          (_, cooperativeIndex) => cooperativeIndex !== index
        ),
      }));
    },
    [entities?.cooperative]
  );

  const handleRemoveGenerationUnitEntity = useCallback(
    (index: number) => {
      const generationUnit = entities?.generationUnit?.find(
        (_, generationUnitIndex) => generationUnitIndex === index
      );

      setSelectOptions((prev) => ({
        ...prev,
        generationUnit: [
          ...prev.generationUnit,
          {
            key: generationUnit?.id as number,
            value: `${generationUnit?.generationUnitLegalName} - ${generationUnit?.powerDistributionUnit?.powerDistributionUnitLegalName}`,
          },
        ],
      }));

      setEntities((prev) => ({
        ...prev,
        generationUnit: prev.generationUnit.filter(
          (_, generationUnitIndex) => generationUnitIndex !== index
        ),
      }));
    },
    [entities?.generationUnit]
  );

  const handleAllConsumerUnitOptionsAreSelected = useCallback(() => {
    return (
      selectOptions.consumerUnit.filter((option) => option.key !== null).length ===
      entities?.consumerUnit?.length
    );
  }, [entities?.consumerUnit, selectOptions.consumerUnit]);

  const onClickCloseModal = useCallback(() => {
    modalCloseConfirm();

    setDomainContact({} as Contacts);
  }, [modalCloseConfirm]);

  const handleShowConfirm = useCallback(
    (
      contactType: ContactTypes,
      contactName: string,
      contactEmail: string,
      contactPhone: string
    ) => {
      modalShowConfirm();

      setDomainContact({
        contactType,
        contactName,
        contactEmail,
        contactPhone,
      } as Contacts);
    },
    [modalShowConfirm]
  );

  const onClickContinue = useCallback(() => {
    modalCloseConfirm();

    domainContact &&
      handleAddContact(
        ContactTypes.DOMAIN,
        domainContact.contactName,
        domainContact.contactEmail,
        domainContact.contactPhone
      );

    setDomainContact({} as Contacts);
  }, [modalCloseConfirm, handleAddContact, domainContact]);

  const HAS_OWNER_EMPTY_INPUTS =
    !ownerContactName || !ownerContactEmail || !ownerContactPhone;

  const HAS_DOMAIN_EMPTY_INPUTS =
    !domainContactName || !domainContactEmail || !domainContactPhone;

  const HAS_TECHNICAL_EMPTY_INPUTS =
    !technicalContactName || !technicalContactEmail || !technicalContactPhone;

  const HAS_JURIDICAL_EMPTY_INPUTS =
    !juridicalContactName || !juridicalContactEmail || !juridicalContactPhone;

  const HAS_FINANCIAL_EMPTY_INPUTS =
    !financialContactName || !financialContactEmail || !financialContactPhone;

  const HAS_ADMINISTRATIVE_EMPTY_INPUTS =
    !administrativeContactName ||
    !administrativeContactEmail ||
    !administrativeContactPhone;

  const HAS_CONSUMER_UNIT_EMPTY_INPUTS =
    !entitySearchBarSelectedOption?.consumerUnit?.length;

  const HAS_COOPERATIVE_EMPTY_INPUTS =
    !entitySearchBarSelectedOption?.cooperative?.length;

  const HAS_GENERATION_UNIT_EMPTY_INPUTS =
    !entitySearchBarSelectedOption?.generationUnit?.length;

  useEffect(() => {
    handleGetContactList(contacts);
  }, [contacts, handleGetContactList]);

  useEffect(() => {
    handleGetEntityList(entities);
  }, [entities, handleGetEntityList]);

  return (
    <>
      <div className="flex flex-col w-full gap-4">
        <div className="grid grid-cols-6">
          <TextField
            required
            id={'groupName'}
            label="Nome do grupo"
            placeholder="Ex: Nome"
            {...register('groupName', {
              required: 'Campo obrigatório',
            })}
            error={errors?.groupName?.message}
          />
        </div>

        <div className="border-b-2 border-b-gray-dark600 w-full mt-3" />

        <div className="flex w-full flex-col gap-4">
          <p className="py-4 font-bold text-sm text-gray-dark400">
            Dados de contato
          </p>
          <Accordion
            required
            title="Proprietários e login"
            error={errors.contacts?.message}
            length={
              filteredContacts(ContactTypes.OWNER).length +
              filteredContacts(ContactTypes.DOMAIN).length
            }
          >
            <div className="flex flex-col items-start w-full">
              <p className="font-medium text-sm text-gray-dark400 py-2">
                Cadastre o proprietário principal e responsável pelo login da área do
                cliente*
              </p>
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    placeholder="Nome*"
                    id="domainContactName"
                    wrapperClassName="w-full"
                    {...register('domainContactName')}
                    disabled={filteredContacts(ContactTypes.DOMAIN).length > 0}
                  />

                  <TextField
                    placeholder="Email*"
                    id="domainContactEmail"
                    wrapperClassName="w-full"
                    {...register('domainContactEmail')}
                    disabled={filteredContacts(ContactTypes.DOMAIN).length > 0}
                  />

                  <TextField
                    mask="mobilePhone"
                    id="domainContactPhone"
                    wrapperClassName="w-full"
                    placeholder="Telefone (Celular)*"
                    {...register('domainContactPhone')}
                    disabled={filteredContacts(ContactTypes.DOMAIN).length > 0}
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={
                    HAS_DOMAIN_EMPTY_INPUTS ||
                    filteredContacts(ContactTypes.DOMAIN).length > 0
                  }
                  onClick={() =>
                    handleShowConfirm(
                      ContactTypes.DOMAIN,
                      domainContactName,
                      domainContactEmail,
                      domainContactPhone
                    )
                  }
                >
                  Adicionar
                </Button>
              </div>
              <p className="font-medium text-sm text-gray-dark400 py-2">
                Outros proprietários
              </p>
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    id="ownerContactName"
                    placeholder="Nome*"
                    wrapperClassName="w-full"
                    {...register('ownerContactName')}
                  />

                  <TextField
                    id="ownerContactEmail"
                    placeholder="Email*"
                    wrapperClassName="w-full"
                    {...register('ownerContactEmail')}
                  />

                  <TextField
                    id="ownerContactPhone"
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    {...register('ownerContactPhone')}
                    placeholder="Telefone (Celular)*"
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_OWNER_EMPTY_INPUTS}
                  onClick={() =>
                    handleAddContact(
                      ContactTypes.OWNER,
                      ownerContactName,
                      ownerContactEmail,
                      ownerContactPhone
                    )
                  }
                >
                  Adicionar
                </Button>
              </div>
              {filteredContacts(ContactTypes.DOMAIN).length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredContacts(ContactTypes.DOMAIN).map((contact, index) => {
                    return (
                      <div
                        key={index + contact.contactName}
                        className="flex flex-col w-full items-center justify-start"
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark400 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark400 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark400 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6.1rem',
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {filteredContacts(ContactTypes.OWNER).length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredContacts(ContactTypes.OWNER).map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                handleRemoveContact(contacts.indexOf(contact))
                              }
                            />
                          </div>
                        </div>
                        {index !==
                          filteredContacts(contact.contactType).length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion
            title="Financeiro"
            length={filteredContacts(ContactTypes.FINANCIAL).length}
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    id="contactName"
                    placeholder="Nome*"
                    wrapperClassName="w-full"
                    {...register('financialContactName')}
                  />

                  <TextField
                    id="contactEmail"
                    placeholder="Email*"
                    wrapperClassName="w-full"
                    {...register('financialContactEmail')}
                  />

                  <TextField
                    id="contactPhone"
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    {...register('financialContactPhone')}
                    placeholder="Telefone (Celular)*"
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_FINANCIAL_EMPTY_INPUTS}
                  onClick={() =>
                    handleAddContact(
                      ContactTypes.FINANCIAL,
                      financialContactName,
                      financialContactEmail,
                      financialContactPhone
                    )
                  }
                >
                  Adicionar
                </Button>
              </div>
              {filteredContacts(ContactTypes.FINANCIAL).length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredContacts(ContactTypes.FINANCIAL).map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                handleRemoveContact(contacts.indexOf(contact))
                              }
                            />
                          </div>
                        </div>
                        {index !==
                          filteredContacts(contact.contactType).length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion
            title="Responsável Técnico"
            length={filteredContacts(ContactTypes.TECHNICAL).length}
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    id="contactName"
                    placeholder="Nome*"
                    wrapperClassName="w-full"
                    {...register('technicalContactName')}
                  />

                  <TextField
                    id="contactEmail"
                    placeholder="Email*"
                    wrapperClassName="w-full"
                    {...register('technicalContactEmail')}
                  />

                  <TextField
                    id="contactPhone"
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    {...register('technicalContactPhone')}
                    placeholder="Telefone (Celular)*"
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_TECHNICAL_EMPTY_INPUTS}
                  onClick={() =>
                    handleAddContact(
                      ContactTypes.TECHNICAL,
                      technicalContactName,
                      technicalContactEmail,
                      technicalContactPhone
                    )
                  }
                >
                  Adicionar
                </Button>
              </div>
              {filteredContacts(ContactTypes.TECHNICAL).length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredContacts(ContactTypes.TECHNICAL).map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                handleRemoveContact(contacts.indexOf(contact))
                              }
                            />
                          </div>
                        </div>
                        {index !==
                          filteredContacts(contact.contactType).length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion
            title="Jurídico"
            length={filteredContacts(ContactTypes.JURIDICAL).length}
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    id="contactName"
                    placeholder="Nome*"
                    wrapperClassName="w-full"
                    {...register('juridicalContactName')}
                  />

                  <TextField
                    id="contactEmail"
                    placeholder="Email*"
                    wrapperClassName="w-full"
                    {...register('juridicalContactEmail')}
                  />

                  <TextField
                    id="contactPhone"
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    {...register('juridicalContactPhone')}
                    placeholder="Telefone (Celular)*"
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_JURIDICAL_EMPTY_INPUTS}
                  onClick={() =>
                    handleAddContact(
                      ContactTypes.JURIDICAL,
                      juridicalContactName,
                      juridicalContactEmail,
                      juridicalContactPhone
                    )
                  }
                >
                  Adicionar
                </Button>
              </div>
              {filteredContacts(ContactTypes.JURIDICAL).length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredContacts(ContactTypes.JURIDICAL).map((contact, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + contact.contactName}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactEmail}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {contact.contactPhone}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() =>
                                handleRemoveContact(contacts.indexOf(contact))
                              }
                            />
                          </div>
                        </div>
                        {index !==
                          filteredContacts(contact.contactType).length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion
            title="Administrativo"
            length={filteredContacts(ContactTypes.ADMINISTRATIVE).length}
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-center gap-4">
                <div className="grid grid-cols-3 gap-4 py-4 justify-between w-full">
                  <TextField
                    id="contactName"
                    placeholder="Nome*"
                    wrapperClassName="w-full"
                    {...register('administrativeContactName')}
                  />

                  <TextField
                    id="contactEmail"
                    placeholder="Email*"
                    wrapperClassName="w-full"
                    {...register('administrativeContactEmail')}
                  />

                  <TextField
                    id="contactPhone"
                    mask="mobilePhone"
                    wrapperClassName="w-full"
                    placeholder="Telefone (Celular)*"
                    {...register('administrativeContactPhone')}
                  />
                </div>

                <Button
                  size="sm"
                  type="button"
                  className="flex items-center justify-center w-24"
                  disabled={HAS_ADMINISTRATIVE_EMPTY_INPUTS}
                  onClick={() =>
                    handleAddContact(
                      ContactTypes.ADMINISTRATIVE,
                      administrativeContactName,
                      administrativeContactEmail,
                      administrativeContactPhone
                    )
                  }
                >
                  Adicionar
                </Button>
              </div>
              {filteredContacts(ContactTypes.ADMINISTRATIVE).length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredContacts(ContactTypes.ADMINISTRATIVE).map(
                    (contact, index) => {
                      return (
                        <div
                          className="flex flex-col w-full items-center justify-start"
                          key={index + contact.contactName}
                        >
                          <div className="flex w-full items-center justify-start">
                            <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                              <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                                {contact.contactName}
                              </p>

                              <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                                {contact.contactEmail}
                              </p>

                              <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                                {contact.contactPhone}
                              </p>
                            </div>

                            <div
                              className="flex items-center justify-center px-10"
                              style={{
                                width: '6rem',
                              }}
                            >
                              <RemoveButton
                                icon={<MdClose size={20} />}
                                onClick={() =>
                                  handleRemoveContact(contacts.indexOf(contact))
                                }
                              />
                            </div>
                          </div>
                          {index !==
                            filteredContacts(contact.contactType).length - 1 && (
                            <div
                              className="border-b w-full h-1"
                              style={{
                                borderColor: '#333333',
                              }}
                            />
                          )}
                        </div>
                      );
                    }
                  )}
                </div>
              )}
            </div>
          </Accordion>
        </div>

        <div className="border-b-2 border-b-gray-dark600 w-full mt-3" />

        <div className="flex w-full flex-col gap-4">
          <div className="py-4 flex flex-col items-start">
            <p className="font-bold text-sm text-gray-dark400">
              Vincular entidades*
            </p>
            <p className="font-light text-sm text-gray-dark400">
              Adicione as entidades vinculadas aos contatos
            </p>
            {!!errors.entities && (
              <p className="font-light text-sm text-red-500">
                {errors.entities?.message}
              </p>
            )}
            {handleAllConsumerUnitOptionsAreSelected() && (
              <p className="font-light text-sm text-red-500">
                *As unidades que não foram selecionadas a esse grupo, precisam ser
                adicionadas a um grupo para serem visíveis na área de cliente
              </p>
            )}
          </div>
          <Accordion
            title="Unidade Consumidora"
            length={filteredConsumerUnits?.length}
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-between gap-4">
                <div className="flex gap-4 py-4 justify-center items-center w-full max-w-3xl">
                  <MultipleSearchInput
                    isAllSelectable
                    id="consumerUnitId"
                    placeholder="Procurar UC"
                    onChange={handleChangeConsumerUnit}
                    options={selectOptions.consumerUnit}
                    selectItem={handleSelectConsumerUnit}
                    error={errors?.consumerUnitId?.message}
                    selectAll={handleSelectAllConsumerUnits}
                    value={entitySearchBarValue.consumerUnit}
                    selectedOption={entitySearchBarSelectedOption.consumerUnit}
                  />

                  <Button
                    size="sm"
                    type="button"
                    disabled={HAS_CONSUMER_UNIT_EMPTY_INPUTS}
                    onClick={() => handleAddEntity('consumerUnit')}
                    className="flex items-center justify-center w-24"
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
              {filteredConsumerUnits?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredConsumerUnits?.map((entity, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + entity?.id}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-4 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-bold text-sm w-full flex items-center justify-start">
                              {entity?.consumerUnitPowerDistributionUnitIdentifier}
                            </p>

                            <p className="text-gray-dark500 font-bold text-sm w-full flex items-center justify-start">
                              {entity?.cooperativeMember?.cooperativeMemberLegalName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {
                                entity?.powerDistributionUnit
                                  ?.powerDistributionUnitLegalName
                              }
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              Bairro - {entity?.consumerUnitAddressDistrict}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() => handleRemoveConsumerUnitEntity(index)}
                            />
                          </div>
                        </div>
                        {index !== filteredConsumerUnits.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion
            title="Unidade Geradora"
            length={filteredGenerationUnits?.length}
          >
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-between gap-4">
                <div className="flex gap-4 py-4 justify-center items-center w-full max-w-3xl">
                  <MultipleSearchInput
                    isAllSelectable
                    id="generationUnitId"
                    placeholder="Procurar UG"
                    onChange={handleChangeGenerationUnit}
                    options={selectOptions.generationUnit}
                    selectItem={handleSelectGenerationUnit}
                    selectAll={handleSelectAllGenerationUnits}
                    error={errors?.generationUnitId?.message}
                    value={entitySearchBarValue.generationUnit}
                    selectedOption={entitySearchBarSelectedOption.generationUnit}
                  />

                  <Button
                    size="sm"
                    type="button"
                    disabled={HAS_GENERATION_UNIT_EMPTY_INPUTS}
                    onClick={() => handleAddEntity('generationUnit')}
                    className="flex items-center justify-center w-24"
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
              {filteredGenerationUnits?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredGenerationUnits?.map((entity, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + entity?.id}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-bold text-sm w-full flex items-center justify-start">
                              {entity?.generationUnitLegalName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {entity?.financialAccount?.financialAccountLegalName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              Concessionária:{' '}
                              {
                                entity?.powerDistributionUnit
                                  ?.powerDistributionUnitLegalName
                              }
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() => handleRemoveGenerationUnitEntity(index)}
                            />
                          </div>
                        </div>
                        {index !== filteredGenerationUnits?.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
          <Accordion title="Cooperativa" length={filteredCooperatives?.length}>
            <div className="flex flex-col items-start w-full">
              <div className="w-full flex items-center justify-between gap-4">
                <div className="flex gap-4 py-4 justify-center items-center w-full max-w-3xl">
                  <MultipleSearchInput
                    isAllSelectable
                    id="cooperativeId"
                    placeholder="Procurar Cooperativa"
                    onChange={handleChangeCooperative}
                    options={selectOptions.cooperative}
                    selectItem={handleSelectCooperative}
                    error={errors?.cooperativeId?.message}
                    selectAll={handleSelectAllCooperatives}
                    value={entitySearchBarValue.cooperative}
                    selectedOption={entitySearchBarSelectedOption.cooperative}
                  />

                  <Button
                    size="sm"
                    type="button"
                    disabled={HAS_COOPERATIVE_EMPTY_INPUTS}
                    onClick={() => handleAddEntity('cooperative')}
                    className="flex items-center justify-center w-24"
                  >
                    Adicionar
                  </Button>
                </div>
              </div>
              {filteredCooperatives?.length > 0 && (
                <div className="flex flex-col items-start w-full">
                  {filteredCooperatives?.map((entity, index) => {
                    return (
                      <div
                        className="flex flex-col w-full items-center justify-start"
                        key={index + entity?.id}
                      >
                        <div className="flex w-full items-center justify-start">
                          <div className="grid grid-cols-3 items-center gap-4 justify-start w-full py-6">
                            <p className="text-gray-dark500 font-bold text-sm w-full flex items-center justify-start">
                              {entity?.cooperativeLegalName}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {entity?.cooperativeDocumentId}
                            </p>

                            <p className="text-gray-dark500 font-medium text-sm w-full flex items-center justify-start">
                              {entity?.financialAccount?.financialAccountLegalName}
                            </p>
                          </div>

                          <div
                            className="flex items-center justify-center px-10"
                            style={{
                              width: '6rem',
                            }}
                          >
                            <RemoveButton
                              icon={<MdClose size={20} />}
                              onClick={() => handleRemoveCooperativeEntity(index)}
                            />
                          </div>
                        </div>
                        {index !== filteredCooperatives?.length - 1 && (
                          <div
                            className="border-b w-full h-1"
                            style={{
                              borderColor: '#333333',
                            }}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </Accordion>
        </div>
      </div>
      <ContactGroupsDangerModal
        danger={DANGER_TOAST}
        ModalOverlay={ModalOverlay}
        onClickContinue={onClickContinue}
        onClickCloseModal={onClickCloseModal}
      />
    </>
  );
}

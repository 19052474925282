import { gql } from '@apollo/client';

import {
  ContactGroups,
  Contacts,
  Entities,
} from 'contactGroups/models/contactGroups';

export interface ContactGroupCreateMutationInput {
  groupName: string;
  entities: Entities;
  contacts: Contacts[];
}

export interface ContactGroupMutationVariables {
  contactGroupCreateInput: ContactGroupCreateMutationInput;
}

export interface ContactGroupCreated {
  contactGroupCreate: ContactGroups;
}

export const CONTACT_GROUP_CREATE_MUTATION = gql`
  mutation contactGroupCreate($contactGroupCreateInput: ContactGroupCreateInput!) {
    contactGroupCreate(input: $contactGroupCreateInput) {
      id
      groupName
      contacts {
        id
        contactName
        contactEmail
        contactPhone
        contactType {
          id
          contactTypeName
        }
      }
      entities {
        cooperative {
          id
        }
        consumerUnit {
          id
        }
        generationUnit {
          id
        }
      }
    }
  }
`;

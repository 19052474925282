import {
  ContactTypes,
  Groups as GroupsModel,
  Contacts as ContactsModel,
  Entities as EntitiesModel,
  ContactGroups as ContactGroupsModel,
} from 'contactGroups/models/contactGroups';

import Cooperative from './cooperative';
import ConsumerUnit from './consumerUnit';
import GenerationUnit from './generationUnit';

export const Contacts = (id = new Date().valueOf()): ContactsModel => ({
  id,
  contactName: `Joe Doe ${id}`,
  contactType: ContactTypes.OWNER,
  contactEmail: `joe${id}@doe.com`,
  contactPhone: `(${id}) 123456789`,
});

export const Groups = (id = new Date().valueOf()): GroupsModel => ({
  id,
  groupName: `Grupo de Contatos ${id}`,
});

export const Entities = (id = new Date().valueOf()): EntitiesModel => ({
  cooperative: [Cooperative(id)],
  consumerUnit: [ConsumerUnit(id)],
  generationUnit: [GenerationUnit(id)],
});

export const ContactGroups = (id = new Date().valueOf()): ContactGroupsModel => ({
  id,
  groups: Groups(id),
  entities: Entities(id),
  contacts: [Contacts(id)],
});

export default ContactGroups;

export function handleValidateDays(month: number, year: number) {
  switch (month) {
    case 2:
      if (year % 4) {
        return 28;
      }
      if (year % 100) {
        return 29;
      }
      if (year % 400) {
        return 28;
      }
      return 29;
    case 9:
    case 4:
    case 6:
    case 11:
      return 30;
    default:
      return 31;
  }
}

export function validateIndividualDocument(document: string): boolean {
  if (document === '00000000000') return false;

  let rest;
  let sum = 0;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(document.substring(i - 1, i)) * (11 - i);
  }

  rest = (sum * 10) % 11;

  if ([10, 11].includes(rest)) rest = 0;
  if (rest !== parseInt(document.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++) {
    sum += parseInt(document.substring(i - 1, i)) * (12 - i);
  }

  rest = (sum * 10) % 11;

  if ([10, 11].includes(rest)) rest = 0;
  if (rest !== parseInt(document.substring(10, 11))) return false;

  return true;
}

const calculateCompanyDocumentDigits = (index: number, numbers: number[]) => {
  let sum = 0;
  let factor = index - 7;

  for (let i = index; i >= 1; i--) {
    const number = numbers[index - i];

    sum += number * factor--;
    if (factor < 2) factor = 9;
  }

  const result = 11 - (sum % 11);
  return result > 9 ? 0 : result;
};

export function validateCompanyDocument(document: string): boolean {
  const numbers = document.split('').map((n) => parseInt(n, 10));

  const digits = numbers.slice(12);
  const digit0 = calculateCompanyDocumentDigits(12, numbers);

  if (digit0 !== digits[0]) return false;

  const digit1 = calculateCompanyDocumentDigits(13, numbers);
  return digit1 === digits[1];
}

export function handleValidateEntityType(entityType: string) {
  switch (entityType) {
    case 'INDIVIDUAL':
      return 'PF';
    case 'COMPANY':
      return 'PJ';
    default:
      break;
  }
}
